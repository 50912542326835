// In components/Header.js
import React, { useState, useEffect } from 'react';
import { faCoins, faUser, faCirclePlus, faCertificate, faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import { useUser } from '../UserContext';
import { supabase } from '../supabaseClient';
import { Wallet } from './Wallet';
const Header = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const { user } = useUser();
    const [profile, setProfile] = useState(null);
    const [history, setHistory] = useState(null);
    const [loading, setLoading] = useState(true);
    const tokenBalanceSample = '0.00'; // This should ideally come from user data

    useEffect(() => {
        if (user) {
            fetchUserProfile();
        }
    }, [user]);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };

    const fetchUserProfile = async () => {
        setLoading(true);

        try {
            const userEmail = user.email; // Assuming `user.email` contains the user's email
            const { data, error } = await supabase
                .from('users') // Your table name
                .select('username, email, avatar_url, balance')
                .eq('email', userEmail) // Use email to fetch user data
                .single();
    
            if (error) {
                throw error;
            }
    
            if (data) {
                setProfile(data);
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoading(false);
        }
    };



    // Function to format the username or email
    const formatUsername = (user) => {
        if (user.username) {
            return user.username;
        } else if (user.email) {
            return user.email.split('@')[0]; // Truncate the email at the '@' symbol
        }
        return 'User'; // Fallback username
    };

    const userName = user ? formatUsername(user) : 'Sign In';
    const userAvatar = profile?.avatar_url || "https://cdn.vectorstock.com/i/preview-1x/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"; // Replace with dynamic user avatar
    const formattedBalance = profile?.balance ? `${profile.balance.toFixed()} EAFC` : '0.00 EAFC';
    const username = profile?.username || user?.email.split('@')[0];
    const [isWalletOpen, setIsWalletOpen] = useState(false);

    const toggleWalletModal = () => {
      setIsWalletOpen(!isWalletOpen);
    };
    return (
        <header className="bg-[#454054] text-white flex xl:flex-row xl:gap-0  gap-3 flex-col items-center p-5 rounded-3xl">

            <div className="flex-1 flex justify-start items-center space-x-2">
                <div  className='flex'>
                    <Link to="/">
                    <img src="/logo.png" alt="EAFC24 Logo" className="h-10" />
                    </Link>        
                </div>
                    <span className="text-3xl font-black italic xl:mb-1" onClick={toggleSidebar}>EAFC24</span>
            </div>
            {user ? (
                <>
                    <div className="flex-1 bg-[#847492] min-w-[200px] max-w-fit h-[40px] rounded-2xl flex py-2 px-4 justify-between items-center space-x-2">
                        <FontAwesomeIcon icon={faCoins} height={16} className="text-lg text-[#FFDC84]" />
                        <span className="text-lg select-none font-medium">{formattedBalance}</span>
                        <>
      <button onClick={toggleWalletModal} className='flex'>
        <FontAwesomeIcon icon={faCirclePlus} height={16} className="balance-button  duration-100 ease-out hover:scale-[1.1] text-lg text-[#FFDC84]" />
      </button>

      {isWalletOpen && <Wallet user={user} onClose={toggleWalletModal} />}
    </>
                    </div>

                    <div className="flex-1 flex justify-end items-center space-x-2">
                        <div className="dropdown-hover relative">
                            <Link to="/profile">
                                <button className="bg-[#201E26] duration-100 ease-out hover:bg-[#201E2695] w-[240px] font-bold py-2 px-2 rounded-2xl z-10 flex items-center justify-between space-x-2">
                                    <img src={userAvatar} alt="Profile" className="h-6 w-6 aspect-[1/1] object-cover rounded-full" />
                                    <span>{userName}</span>
                                    <FontAwesomeIcon icon={faCertificate} height={16} className="text-lg text-[#454054]" />
                                </button>
                            </Link>

                            {/* Dropdown menu here */}
                        </div>
                    </div>
                </>
            ) : (
                <Link to="/signin">
                    <button className="bg-[#201E26] hover:bg-[#201E2695] lg:w-[240px] w-[150px] font-bold py-2 px-4 rounded-2xl flex items-center justify-center text-center space-x-2">Sign in</button>
                </Link>
            )}
        </header>
    );
};

export default Header;
