import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';

const ResultSubmissionModal = ({ lobbyId, hostUsername, opponentUsername, betAmount, onClose }) => {
  const [screenshot, setScreenshot] = useState(null);
  const [preview, setPreview] = useState('');
  const [hostAvatar, setHostAvatar] = useState('');
  const [opponentAvatar, setOpponentAvatar] = useState('');
  const [hostScore, setHostScore] = useState('');
  const [opponentScore, setOpponentScore] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const fetchAvatars = async () => {
      // Fetch avatars for host
      const { data: hostData } = await supabase
        .from('users')
        .select('avatar_url')
        .eq('username', hostUsername)
        .single();
      setHostAvatar(hostData?.avatar_url);

      // Fetch avatars for opponent
      const { data: opponentData } = await supabase
        .from('users')
        .select('avatar_url')
        .eq('username', opponentUsername)
        .single();
      setOpponentAvatar(opponentData?.avatar_url);
    };

    fetchAvatars();
  }, [hostUsername, opponentUsername]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setScreenshot(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setUploading(true);

    // Upload screenshot to 'screenshots' bucket
    const { data: uploadData, error: uploadError } = await supabase.storage
      .from('screenshots')
      .upload(`screenshots/${Date.now()}-${screenshot.name}`, screenshot);

    if (uploadError) {
      console.error('Error uploading file:', uploadError);
      return;
    }

    // Manually construct the public URL for the 'screenshots' bucket
    const bucketName = 'screenshots'; // Replace with your bucket name
    const filePath = `${Date.now()}-${screenshot.name}`;
    const screenshotUrl = `https://aigwuvyeurrzchcadlpf.supabase.co/storage/v1/object/${bucketName}/${filePath}`;

    // Create a new result entry in the 'results' table
    const { error: resultError } = await supabase
      .from('results')
      .insert([{ 
        lobby_id: lobbyId, 
        host_username: hostUsername, 
        opponent_username: opponentUsername, 
        bet_amount: betAmount, 
        host_score: hostScore,
        opponent_score: opponentScore,
        screenshot_url: screenshotUrl 
      }]);

    if (resultError) {
      console.error('Error submitting result:', resultError);
    } else {
      onClose(); // Close the modal
    }

    setUploading(false);
  };

  return (
    <div className="fixed inset-0 bg-[#454054] bg-opacity-75 flex justify-center items-center">
      <div className="bg-[#201E26] p-6 rounded-2xl shadow-2xl max-w-md w-full">
        <h2 className="text-lg font-bold text-white mb-4">Finalize Match Result</h2>

        <div className="flex justify-around items-center mb-4">
          <div className="text-center">
            <img src={hostAvatar || 'default-avatar.png'} alt={hostUsername} className="rounded-full mx-auto w-16 h-16 mb-2" />
            <p className="text-white mb-2">{hostUsername}</p>
            <input 
              type="text" 
              placeholder="Host Score" 
              value={hostScore} 
              onChange={(e) => setHostScore(e.target.value)} 
              className="text-center bg-[#454054] text-white rounded-lg p-2 outline-none w-24"
            />
          </div>
          <div className="text-center">
            <img src={opponentAvatar || 'default-avatar.png'} alt={opponentUsername} className="rounded-full mx-auto w-16 h-16 mb-2" />
            <p className="text-white mb-2">{opponentUsername}</p>
            <input 
              type="text" 
              placeholder="Opponent Score" 
              value={opponentScore} 
              onChange={(e) => setOpponentScore(e.target.value)} 
              className="text-center bg-[#454054] text-white rounded-lg p-2 outline-none w-24"
            />
          </div>
        </div>

        {/* Custom File Input Button */}
        <label className="block w-full bg-[#454054] text-white text-center py-2 px-4 rounded-2xl cursor-pointer mb-4">
          <span>Upload Screenshot</span>
          <input type="file" onChange={handleFileChange} className="hidden" />
        </label>

        {/* Image Preview */}
        {preview && (
          <img src={preview} alt="Screenshot Preview" className="mb-5 rounded-3xl outline outline-2 outline-offset-5 outline-[#ffffff05] outline-solid" />
        )}

        {/* Submit and Close Buttons */}
        <button 
          onClick={handleSubmit} 
          disabled={uploading}
          className="w-full bg-green-400 text-white py-2 px-4 rounded-2xl hover:bg-green-500"
        >
          Submit Result
        </button>
        <button 
          onClick={onClose} 
          className="mt-4 w-full bg-red-400 text-white py-2 px-4 rounded-2xl hover:bg-red-500"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ResultSubmissionModal;
