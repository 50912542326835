// In components/Header.js
import React, { useState, useEffect } from 'react';
import { faUser, faWallet, faGamepad, faSoccerBall, faTableCells, faClock, faMoneyBill, faBitcoinSign, faEthernet, faStore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { useUser } from '../UserContext';
import { supabase } from '../supabaseClient';
const Sidebar = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const { user } = useUser();
  const [profile, setProfile] = useState(null);
  const [history, setHistory] = useState(null);
  const [loading, setLoading] = useState(true);
  const tokenBalanceSample = '0.00'; // This should ideally come from user data

  useEffect(() => {
      if (user) {
          fetchUserProfile();
      }
  }, [user]);
  // Function to close the sidebar, if needed
  // Function to close the sidebar
  const closeSidebar = () => {
    setIsSidebarOpen(false); // Update this to close the sidebar
  };
  
  const fetchUserProfile = async () => {
      setLoading(true);

      try {
          const userEmail = user.email; // Assuming `user.email` contains the user's email
          const { data, error } = await supabase
              .from('users') // Your table name
              .select('username, email, avatar_url, balance')
              .eq('email', userEmail) // Use email to fetch user data
              .single();
  
          if (error) {
              throw error;
          }
  
          if (data) {
              setProfile(data);
          }
      } catch (error) {
          console.error('Error fetching profile:', error);
      } finally {
          setLoading(false);
      }
  };
    return  (
      <>
      {/* Overlay, only shown when the sidebar is open on mobile and tablet */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10 2xl:hidden" // Hide on 2xl and larger screens
        >
        </div>
      )}

      {/* Sidebar */}
      <aside className={`bg-[#454054] rounded-none mr-5 w-64 min-h-screen p-5 2xl:rounded-3xl text-white 
        fixed inset-y-0 left-0 transform 2xl:relative 2xl:translate-x-0
        ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
        transition-transform duration-300 ease-out z-20`}> 
        <button      className='text-sm mb-2 font-extrabold text-xl w-full py-1 px-2 text-center rounded-2xl bg-red-400 hover:bg-red-500 m-auto 2xl:hidden'     onClick={closeSidebar}>Close</button>
        {user ? (
  <nav>
    
<div className="text-sm mb-2 font-extrabold text-xl w-full py-1 px-2 text-center rounded-2xl bg-[#847492] m-auto">Manage</div>
  <ul>
    <li className="mb-4">
      <a href="/profile" className="flex items-center text-sm font-bold">
        <FontAwesomeIcon height={18} color='white' icon={faUser} className="mr-2" />
        Profile Settings
      </a>
    </li>
    <li className="mb-4">
      <a href="#" className="flex items-center text-sm font-bold">
        <FontAwesomeIcon height={18} color='white' icon={faWallet} className="mr-2" />
        Manage Wallet
      </a>
    </li>

    <li className="mb-4">
      <a href="#" className="flex items-center text-sm font-bold">
        <FontAwesomeIcon height={18} color='white' icon={faTableCells} className="mr-2" />
        Active Games
      </a>
    </li>

    <li className="mb-4">
      <a href="#" className="flex items-center text-sm font-bold">
        <FontAwesomeIcon height={18} color='white' icon={faClock} className="mr-2" />
        Game History
      </a>
    </li>




  </ul>
</nav>
  ) : (
<div></div>
    )}



  

<div className="my-8">
  <div className="text-sm mb-2 font-extrabold text-xl w-full py-1 px-2 text-center rounded-2xl bg-[#847492] m-auto">Things to Do</div>
  <ul>
    <li className="mb-4">
        <Link to="/matchmaking" className="flex items-center text-sm font-bold">
      <a href="#" className="flex items-center text-sm font-bold">
        <FontAwesomeIcon height={18} color='white' icon={faGamepad} className="mr-2" />
        Matchmaking
      </a></Link>
    </li>

  </ul>
</div>

<div>
  <div className="text-sm mb-2 font-extrabold text-xl w-full py-1 px-2 text-center rounded-2xl bg-[#847492] m-auto">Events</div>
      {/* User is signed in */}
      <div className="text-sm font-bold">Coming Soon</div>


      {/* User is not signed in */}
      <div className="text-sm">Click to get updates</div>
      <Link className="flex items-center bg-[#201E26] hover:bg-[#201E2695] w-[100%] font-bold py-2 px-4 rounded-2xl flex items-center justify-center text-center space-x-2 mt-2 text-sm text-[#FFDC84] font-bold" to="/swap">
        <FontAwesomeIcon height={18} color='white' icon={faStore} className="mr-2 text-[#FFDC84]" />
        Purchase $EAFC24
      </Link>
</div>
</aside>
</>

    );
};

export default Sidebar;
