// Withdraw.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
// Withdraw.js
const Withdraw = ({ user, onWithdraw }) => {
    const [amount, setAmount] = useState('');
    const [processingTime] = useState('~12 hours');
    const [conversionRate] = useState('1 EAFC = 1 EAFC'); // Example rate, adjust as needed
    const [balance, setBalance] = useState(0);
    const newBalance = balance * conversionRate;
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState('');

    useEffect(() => {
        if (user) {
            fetchUserProfile();
        }
    }, [user]);


    const fetchUserProfile = async () => {
        setLoading(true);

        try {
            const userEmail = user.email; // Assuming `user.email` contains the user's email
            const { data, error } = await supabase
                .from('users') // Your table name
                .select('username, email, avatar_url, balance')
                .eq('email', userEmail) // Use email to fetch user data
                .single();

            if (error) {
                throw error;
            }

            if (data) {
                setProfile(data);
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoading(false);
        }
    };
  
    const handleWithdraw = async () => {
        const withdrawAmount = parseFloat(amount);
        if (isNaN(withdrawAmount) || withdrawAmount <= 0) {
            alert('Please enter a valid amount.');
            return;
        }
    
        if (withdrawAmount > profile.balance) {
            alert('Insufficient balance.');
            return;
        }
    
        if (!address) {
            alert('Please enter a cashout address.');
            return;
        }
    
        // Update user's balance
        try {
            const newBalance = profile.balance - withdrawAmount;
            const { error: updateError } = await supabase
                .from('users')
                .update({ balance: newBalance })
                .eq('username', profile.username); // Assuming username is unique and used for reference
    
            if (updateError) throw updateError;
    
            // Record the withdrawal request including the cashout address
            const { error: withdrawalRequestError } = await supabase
                .from('withdrawals')
                .insert([
                    { 
                        username: profile.username, 
                        amount: withdrawAmount, 
                        cashout_address: address, // Adding the cashout address
                        status: 'pending' // Assuming your withdrawal table structure
                    }
                ]);
    
            if (withdrawalRequestError) throw withdrawalRequestError;
    
            // Update the balance in the UI
            onWithdraw(newBalance);
            setProfile({ ...profile, balance: newBalance });
            alert('Withdrawal request submitted.');
        } catch (error) {
            console.error('Withdrawal error:', error);
            alert('Error processing withdrawal.');
        }
    };
    
    
      
    const formattedBalance = profile?.balance ? `${profile.balance.toFixed()} EAFC` : '0.00 EAFC';

    return (
      <div className="p-4">
        <h3 className="text-white text-lg font-bold mb-2">Withdraw EAFC24 Tokens</h3>
        <div className="text-white mb-4">
          <p>Processing Time: {processingTime}</p>
          <p>Your Balance: {formattedBalance}</p>
        </div>
  
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full rounded-2xl p-2 mb-4 text-black"
          placeholder="Amount to withdraw"
        />
                    <div className="mb-4">
                <input
                    type="text"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="w-full p-2 rounded-2xl text-black rounded"
                    placeholder="Cashout Address"
                />
            </div>
        <button 
          onClick={handleWithdraw} 
          className="w-full bg-green-400 text-white py-2 px-4 rounded-2xl hover:bg-green-500"
        >
          Request Withdrawal
        </button>
  
        <div className="text-yellow-400 mt-4 text-center text-sm">
          <strong>Note:</strong> Withdrawals may take {processingTime} to process.
        </div>
      </div>
    );
  };
  
  export default Withdraw;
  