// ChatBox.js
const ChatBox = () => {
    return (
        <div className="bg-[#454054] p-4 mt-2 rounded-2xl">
            <p className="text-gray-300 text-xl tracking-tighter font-bold mb-2">Chat</p>
            <div className="flex items-center justify-between mb-4">
                <span className="text-gray-300 text-sm">Chat messages will appear here.</span>
            </div>
            <div className="flex items-center justify-between mb-4">
                <input type="text" placeholder="Message..." className="w-full tracking-tight py-3 px-4 font-medium bg-[#201E26] text-white rounded-2xl" />
                <button className='w-fit px-4 py-3 bg-[#201E26] text-white rounded-2xl ml-4 text font-medium tracking-tighter text-md'>Send</button>
            </div>
        </div>
    );
};

export default ChatBox;
