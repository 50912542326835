// JoinLobbyButton.js
import React from 'react';
import { supabase } from '../supabaseClient';

const JoinLobbyButton = ({ lobbyId, user, lobbyData, onJoinSuccess, onJoinError }) => {
  const handleJoinLobby = async () => {
    if (!user || user.username === lobbyData.host) {
      onJoinError("Cannot join your own lobby or join without logging in.");
      return;
    }

    // Fetch the latest user profile to get the current balance
    const { data: userProfile, error: profileError } = await supabase
      .from('users')
      .select('balance')
      .eq('username', user.username) // Or 'email', depending on your user identification strategy
      .single();

    if (profileError) {
      onJoinError("Error fetching user profile.");
      return;
    }

    // Check if user has enough balance
    if (userProfile.balance < lobbyData.bet_amount) {
      onJoinError("Insufficient balance to join this lobby.");
      return;
    }

    try {
      // Join lobby logic
      const { error: joinError } = await supabase
        .from('lobbies')
        .update({ opponent: user.username, status: 'waiting to start' })
        .eq('id', lobbyId);

      if (joinError) throw joinError;

      // Deduct balance logic
      const newBalance = userProfile.balance - lobbyData.bet_amount;
      const { error: balanceError } = await supabase
        .from('users')
        .update({ balance: newBalance })
        .eq('username', user.username);

      if (balanceError) throw balanceError;

      onJoinSuccess();
    } catch (error) {
      console.error('Error joining lobby:', error);
      onJoinError(error.message);
    }
  };

  return (
    <button className="bg-[#454054] hover:bg-[#45405495] w-[100%] font-bold text-white py-2 px-4 rounded-2xl flex items-center justify-center text-center space-x-2 mt-2" onClick={handleJoinLobby}>Join Lobby</button>
  );
};

export default JoinLobbyButton;
