// src/components/ScanAndCountdown.js
import React, { useState, useEffect } from 'react';
import Web3 from 'web3';
import animationData from '../lotties/check.json'; // Adjust the path to your Lottie file
import Lottie from 'lottie-react';
const ScanAndCountdown = ({ user, depositAmount, onScanComplete, walletAddress }) => {
  const [countdown, setCountdown] = useState(600); // 15 minutes countdown
  const scanInterval = 10; // Scan every 10 seconds
  const blocksToCheck = 3; // Number of blocks to check
  const [isTransactionFound, setIsTransactionFound] = useState(false);
  const [animationState, setAnimationState] = useState({
    isStopped: false, isPaused: false, direction: 1, isPlaying: false,
  });

  const defaultOptions = {

  };
  const style = {
    height: 96,
    width: 96
  };

  // Function to format the remaining time
  const formatTimeRemaining = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes} : ${remainingSeconds}`;
  };

  // Initialize Web3 instance
  const web3 = new Web3("https://bitter-spring-fog.bsc.quiknode.pro/7359dbbfe7bbfcba7eca8f9cbb05e009dd113357/");

  const checkTransaction = async () => {
    console.log('Checking for token transfers...');
    const url = `https://api.bscscan.com/api?module=account&action=tokentx&contractaddress=0xdBEB2e26117A1dd6F5A879139f1252d396ad3c34&address=${walletAddress}&page=1&offset=100&startblock=0&endblock=999999999&sort=asc&apikey=HPNDXPRUF49T7ZYY15P1YAAJ8G282TKRTC`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      for (const tx of data.result) {
        // Convert the transaction value from Wei and compare
        const txValue = web3.utils.fromWei(tx.value, 'ether');
        console.log(`Transaction value: ${txValue}`);
        if (tx.to.toLowerCase() === walletAddress.toLowerCase() && parseFloat(txValue) === parseFloat(depositAmount)) {
          console.log(`Matching transaction found. Sender: ${tx.from}`);
          return tx.from; // Return the sender's address
        }
      }
      console.log('No matching transaction found.');
      return null;
    } catch (error) {
      console.error('Error checking token transfers:', error);
      return null;
    }
  };

  
  

  useEffect(() => {
    let timer;
    if (!isTransactionFound && countdown > 0) {
      timer = setInterval(async () => {
        setCountdown(countdown - scanInterval);
        console.log(`Countdown updated: ${countdown} seconds remaining.`);
        const transactionFound = await checkTransaction();
        if (transactionFound) {
          console.log('Transaction found. Completing scan.');
          setIsTransactionFound(true); // Set state to indicate transaction is found
          onScanComplete(true);
        }
      }, scanInterval * 1000);
    } else if (countdown <= 0) {
      console.log('Countdown finished. No transaction found.');
      onScanComplete(false);
    }

    return () => clearInterval(timer); // Cleanup interval on unmount or when dependencies change
  }, [countdown, isTransactionFound, user, depositAmount, onScanComplete, walletAddress]); // Add isTransactionFound to dependencies


  return (
    <div>
      {isTransactionFound ? (
        <div className="ml-[-2rem] success-animation flex align-center items-center gap-0 justify-center">
          <Lottie 
            options={defaultOptions}
            style={style}
            animationData={animationData}
            loop='false'
            autoplay='false'
            isStopped={animationState.isStopped}
            isPaused={animationState.isPaused}
            direction={animationState.direction}
            isClickToPauseDisabled={true}
          />
          <p className='ml-[-1.5rem]'>Deposited <span className='p-2 mx-2 rounded-xl text-[#4ADE80] bg-[#454054]'>{depositAmount}</span> EAFC</p>
        </div>
      ) : countdown > 0 ? (
        <div className='flex w-full flex-col items-center justify-center gap-2 mt-5'>
          <div className="spinner"></div>
          <p>Scanning for transaction...</p>
          <span className='w-full text-center'>{formatTimeRemaining(countdown)}</span>
        </div>
      ) : (
        <p>Transaction not found. Please try again or contact support.</p>
      )}
    </div>
  );
};

export default ScanAndCountdown;
