import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode.react';
import ScanAndCountdown from './ScanAndCountdown';
import DepositStatus from './DepositStatus';
import { supabase } from '../supabaseClient';

export const Deposit = ({ user, onDeposit }) => {
  const [baseAmount, setBaseAmount] = useState('');
  const [depositAmount, setDepositAmount] = useState('');
  const commonWalletAddress = '0xEc8D7CBC1Db9C0A162Bd8F404A10ED901eaAf595';
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const contractAddress = '0xdBEB2e26117A1dd6F5A879139f1252d396ad3c34'; // Contract address

  // Fetch the user's profile
  useEffect(() => {
    if (user) {
      fetchUserProfile();
    }
  }, [user]);

  const fetchUserProfile = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('users')
        .select('username, email, balance')
        .eq('email', user.email)
        .single();

      if (error) throw error;
      if (data) setProfile(data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle deposit logic
  const handleDeposit = async () => {
    // Generate a unique amount by adding a small random fraction
    const uniqueIdentifier = (10 * Math.random(10, 90)) + Math.random() * 0.99 * 1000; // EAFC amount // Random cents
    const totalDepositAmount = parseFloat(baseAmount) + uniqueIdentifier;
    setDepositAmount(totalDepositAmount.toFixed(2));
  };

  const qrCodeValue = `https://metamask.app.link/send/${contractAddress}@56/transfer?address=${commonWalletAddress}&uint256=${depositAmount}e18`;

const handleScanComplete = async (success, senderAddress) => {
    if (success && profile) {
        // Calculate the new balance
        const newBalance = parseFloat(profile.balance) + parseFloat(depositAmount);

        try {
            // Update the user's bsc_address and balance in Supabase
            const { error } = await supabase
                .from('users')
                .update({ 
                    bsc_address: senderAddress, 
                    balance: newBalance // Update the balance
                })
                .eq('username', profile.username);

            if (error) throw error;

            // Update the balance in the local state
            setProfile({ ...profile, balance: newBalance });
            onDeposit(newBalance); // Update parent component's state if needed
        } catch (error) {
            console.error('Error updating user data in Supabase:', error);
        }
    } else {
        console.error('Transaction successful but user information is missing.');
    }
};


  return (
    <div className="deposit mb-4">
      <input
        type="number"
        value={baseAmount}
        onChange={(e) => setBaseAmount(e.target.value)}
        className="w-full rounded-2xl p-2 mb-4 text-black"
        placeholder="Enter deposit amount"
      />
      <button onClick={handleDeposit} className="w-full duration-150 ease-out bg-blue-400 text-white py-2 px-4 rounded-2xl hover:bg-blue-500">
        Initialize Deposit
      </button>

      {depositAmount && (
        <>
          <p className="text-white mt-4">Please send <i>exactly</i> {depositAmount} EAFC to:</p>
          <p className="text-white mb-4">{commonWalletAddress}</p>
          <div className="">
            <p className="text-center font-bold tracking-tight mb-2">Scan QR Code with Wallet App</p>
            <div className="bg-white p-5 mx-auto rounded-3xl aspect-[1/1] w-fit justify-center items-center flex">
              <QRCode value={qrCodeValue} size={128} className="mx-auto" />
            </div>
          </div>
          <ScanAndCountdown
            user={user}
            depositAmount={depositAmount}
            onScanComplete={handleScanComplete}
            walletAddress={commonWalletAddress}
          />
        </>
      )}
    </div>
  );
};

export default Deposit;
