// Wallet.js
import React, { useState, useEffect } from 'react';
import { Deposit } from './Deposit';
import Withdraw from './Withdraw';
import { supabase } from '../supabaseClient';
export const Wallet = ({ user, onClose }) => {
    const [balance, setBalance] = useState(0);
    const [activeTab, setActiveTab] = useState('deposit');
    const [profile, setProfile] = useState(null);
    const [history, setHistory] = useState(null);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (user) {
            fetchUserProfile();
        }
    }, [user]);

    
    const fetchUserProfile = async () => {
        setLoading(true);

        try {
            const userEmail = user.email; // Assuming `user.email` contains the user's email
            const { data, error } = await supabase
                .from('users') // Your table name
                .select('username, email, avatar_url, balance, bsc_address')
                .eq('email', userEmail) // Use email to fetch user data
                .single();
    
            if (error) {
                throw error;
            }
    
            if (data) {
                setProfile(data);
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };
    const formattedBalance = profile?.balance ? `${profile.balance.toFixed()} EAFC` : '0.00 EAFC';

    return (
        <div className="fixed inset-0 bg-[#454054] z-40 bg-opacity-75 flex justify-center items-center">
            <div className="wallet-modal bg-[#201E26] p-6 rounded-3xl shadow-2xl max-w-md w-[90%] md:w-full z-50">
                <div className="text-yellow-400 mb-2 text-sm mb-4 p-4 bg-[#454054] rounded-3xl">
                    <strong className='mb-2'>Read Before Proceeding:</strong> <br/>Only send tokens on the Binance Smart Chain (BSC). We are not responsible for lost tokens sent on other networks.
                </div>
                <h2 className="text-lg font-bold text-white mb-4">Your Balance: {formattedBalance}</h2>
                
                <div className="flex bg-[#454054] gap-2 rounded-2xl p-2 mb-4">
                    <button 
                        className={`flex-1 py-2 text-white  duration-150 ease-out ${activeTab === 'deposit' ? 'bg-[#201E26]' : 'hover:bg-[#201E2699]  bg-[#454054]'} rounded-2xl`}
                        onClick={() => handleTabChange('deposit')}
                    >
                        Deposit
                    </button>
                    <button 
                        className={`flex-1 py-2 text-white duration-150 ease-out ${activeTab === 'withdraw' ? 'bg-[#201E26]' : 'hover:bg-[#201E2699] bg-[#454054]'} rounded-2xl`}
                        onClick={() => handleTabChange('withdraw')}
                    >
                        Withdraw
                    </button>
                </div>

                {activeTab === 'deposit' && (
    <Deposit user={user} onDeposit={setBalance} profile={profile} />
)}

                {activeTab === 'withdraw' && (
                    <Withdraw user={user} onWithdraw={setBalance} profile={profile} />
                )}

                <button 
                    onClick={onClose} 
                    className="mt-4 duration-150 ease-out w-full bg-red-400 text-white py-2 px-4 rounded-2xl hover:bg-red-500"
                >
                    Close
                </button>
            </div>
        </div>
    );
};
