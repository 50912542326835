// ScoreDisplay.js
const ScoreDisplay = ({ hostScore, opponentScore, status, hostTimeoutsLeft, opponentTimeoutsLeft, countdown }) => {
    return (
        <div className="text-center">
            <p className="text-white text-4xl font-medium tracking-tighter">{hostScore} - {opponentScore}</p>
            <p className="text-white mt-2">{countdown ? `Starting in ${countdown}...` : `${status}`}</p>
            <div className="text-white font-medium tracking-tighter">Timeouts Left:<br/> {hostTimeoutsLeft} |  {opponentTimeoutsLeft}</div>
        </div>
    );
};

export default ScoreDisplay;
