// In components/Header.js
import React from 'react';
import { faUser, faWallet, faGamepad, faSoccerBall } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { supabase } from '../supabaseClient';
const Footer = () => {
  const handleLogout = async () => {
    await supabase.auth.signOut();
    // You can also reset the user context or redirect to the home page here
  };  
    return  (

        <header className="bg-[#454054] mt-5 text-white flex items-center p-5 rounded-3xl">
        <div className="flex-1 flex justify-start items-center space-x-2">
          {/* Replace with the path to your logo */}
          <img src="/logo.png" alt="EAFC24 Logo" className="h-10" />
          <span className="text-2xl font-black italic mb-1">EAFC24</span>
        </div>
        
        {/* Token balance centered */}
        <span className="text-lg xl:block hidden font-medium">Built with ❤️ by calvinheath.eth</span>
  
        {/* Conditionally render the Sign Out button */}
          <div className="flex-1 flex justify-end items-center space-x-2">
            {/* Profile button aligned to the right */}
            <button
        onClick={handleLogout}
        className="py-2 px-4 bg-[#201E26] hover:bg-red-400 text-white font-bold rounded-2xl"

      >
        Log Out
      </button>
          </div>
      </header>
    );
};

export default Footer;
