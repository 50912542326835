// PlayerInfo.js
const PlayerInfo = ({ username, avatarUrl, betAmount }) => {
    const defaultAvatar = 'https://cdn.vectorstock.com/i/preview-1x/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg';
    const imageUrl = avatarUrl || defaultAvatar;

    return (
        <div className="text-center flex-col items-center justify-center min-w-[100px]">
            <div>
            <img src={imageUrl} alt={`${username} Avatar`} className="w-16 aspect-[1/1] object-cover outline outline-2 outline-offset-2 outline-[#454054] h-16 rounded-full mx-auto" />
            </div>
            <p className="text-white mx-auto mt-2">{username}</p>
            <p className="text-green-400 mx-auto font-bold py-2 bg-[#454054] rounded-2xl mt-3">{betAmount} EAFC</p>
        </div>
    );
};

export default PlayerInfo;
