import { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';
const UsernameUpdate = ({ initialUsername }) => {
    const { user } = useUser();

    const [username, setUsername] = useState(initialUsername);
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);
    const [updateSuccess, setUpdateSuccess] = useState(false);

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    };

    const handleUsernameUpdate = async () => {
        setUpdating(true);
        setUpdateError(null);
        setUpdateSuccess(false);

        try {
            const { error } = await supabase
                .from('users')
                .update({ username: username })
                .eq('email', user.email);

            if (error) throw error;
            setUpdateSuccess(true);
        } catch (error) {
            setUpdateError(error.message);
        } finally {
            setUpdating(false);
        }
    };

    return (
        <div className=' justify-center  text-white  items-center'>
            <div className='flex-1 outline-white-sm flex gap-2 items-center bg-[#201E2650] w-fit rounded-2xl'>
            <input type="text" className="px-4 bg-[#201E26] py-2 rounded-2xl outline-none" value={username} onChange={handleUsernameChange} disabled={updating} />
            <button onClick={handleUsernameUpdate} className='px-4 py-2 font-bold rounded-2xl' disabled={updating}>Save</button>
            </div>
            {updating && <p>Updating...</p>}
            {updateError && <p style={{ color: 'red' }}>{updateError}</p>}
            {updateSuccess && <p style={{ color: 'green' }}>Username updated successfully!</p>}
        </div>
    );
};

export default UsernameUpdate;
