import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './page/Home';
import SignIn from './page/SignIn';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import SignUp from './page/SignUp';
import Footer from './components/Footer';
import reportWebVitals from './reportWebVitals';
import Profile from './page/Profile';
import { UserProvider } from './UserContext';
import ProfileEdit from './page/ProfileEdit';
import Lobbies from './page/Lobbies';
import MatchPage from './components/MatchPage';
import Swap from './page/Swap';
const App = () => {
  // State to manage sidebar visibility
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);



  return (
    <UserProvider>
      <BrowserRouter>
        <div className={`max-w-screen-2xl m-auto p-[20px]`}>


          {/* Header */}
          <Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />

          {/* Content */}
          <div className="flex pt-[20px]">
            {/* Sidebar */}
            <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}  />

            {/* Main content */}
            <main className="flex-1 max-w-screen-2xl m-auto">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/profile/edit" element={<ProfileEdit />} />
                <Route path="/matchmaking" element={<Lobbies />} />
                <Route path="/match/:lobbyId" element={<MatchPage />} />
                <Route path="/swap" element={<Swap />} />

                {/* ... other routes */}
              </Routes>
            </main>
          </div>

          {/* Footer */}
          <Footer />
        </div>
      </BrowserRouter>
    </UserProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
