import { useState } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';

const FileUpload = () => {
  const { user } = useUser();
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [uploadError, setUploadError] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!file) {
      setUploadError('Upload an image, then try again.');
      return;
    }

    setUploading(true);
    setUploadError(null);
    setUploadSuccess(false);

    const filePath = `avatars/${user.id}/${file.name}`;
    console.log(`Uploading file to path: ${filePath}`);

    try {
      const { error: uploadError } = await supabase.storage.from('avatars').upload(filePath, file, {
        upsert: true
      });

      if (uploadError) {
        throw new Error(`Error uploading file: ${uploadError.message}`);
      }

      console.log('File uploaded successfully');

      // Manually construct the public URL
      const publicURL = `https://aigwuvyeurrzchcadlpf.supabase.co/storage/v1/object/public/avatars/${filePath}`;
      console.log(`Uploaded file URL: ${publicURL}`);

      const { error: updateError } = await supabase
        .from('users')
        .update({ avatar_url: publicURL })
        .eq('email', user.email);

      if (updateError) {
        throw new Error(`Error updating user profile: ${updateError.message}`);
      }

      console.log('User profile updated successfully');
      setUploadSuccess(true);
    } catch (error) {
      console.error(error);
      setUploadError(error.message);
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className='flex flex-col space-y-4'>
<div className="flex items-center space-x-4">
  <label className="cursor-pointer bg-[#847492] text-white py-2 px-4 rounded-lg  duration-100 ease-out hover:bg-[#847492CC]">
    <span>Change Avatar</span>
    <input type="file" onChange={handleFileChange} disabled={uploading} className="hidden" />
  </label>

  <button onClick={handleFileUpload} disabled={uploading} className="bg-[#201E26]  duration-100 ease-out hover:bg-[#201E2695] text-white py-2 px-4 rounded-lg">
    {uploading ? 'Uploading...' : 'Save and Upload'}
  </button>



</div>
<div className='mx-auto'>
{uploading && <p>Uploading...</p>}
  {uploadError && <p className="text-red-500">{uploadError}</p>}
  {uploadSuccess && <p className="text-green-500">File uploaded successfully!</p>}

  </div>
</div>
  );
};

export default FileUpload;
