// WaitingForPlayer.js
import JoinLobbyButton from "./JoinLobbyButton";

const WaitingForPlayer = ({ lobbyId, user, lobbyData, onJoinSuccess, onJoinError }) => {
    return (
        <div className="text-center flex-col items-center justify-center min-w-[100px]">
            <img src="https://cdna.artstation.com/p/assets/images/images/029/382/866/original/yunna-minkova-boll.gif?1597353344" alt="Waiting for player" className="w-16 h-16 m-auto outline outline-2 outline-offset-2 outline-[#454054] rounded-full" />
            <p className="text-gray-300 mx-auto mt-2">Waiting for player</p>
            <JoinLobbyButton 
                lobbyId={lobbyId} 
                user={user} 
                lobbyData={lobbyData} 
                onJoinSuccess={onJoinSuccess}
                onJoinError={onJoinError}
            />
        </div>
    );
};

export default WaitingForPlayer;
