import { Helmet } from 'react-helmet';
import React from 'react';

function Swap() {


  return (
    
    <div className="Swap">
    <Helmet>
      <title>Swap | The Ultimate FIFA Betting Experience</title>
      <meta name="description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:title" content="Swap | The Ultimate FIFA Betting Experience" />
      <meta property="og:description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:image" content="https://cdn.discordapp.com/attachments/798815198668259399/1181781254601982084/Group_58.png?ex=65824e8f&is=656fd98f&hm=7856c32222ec320b59f463cc7958fbd619a193a4dc333bbcde59655a7eb8f96e&" />
      
      {/* Other tags as needed */}
    </Helmet>
      <main className="flex max-w-[100%] md:max-w-[50%] mx-auto text-white flex-col rounded-3xl min-h-screen">

      <div className="flex flex-col gap-5 max-w-[100%] md:max-w-[100%] items-center justify-apart">
        <h1 className="text-4xl max-w-[50%] md:max-w-[100%] font-bold">Buy or Sell EAFC24</h1>
        <p className='warning-text max-w-[80%] md:max-w-[100%] text-lg mt-4 mb-2 font-light'> You can buy or sell EAFC24 using our easy and secure swap feature below </p>
        <span className="flex max-w-[80%] font-bold md:max-w-[80%] items-center justify-between w-full">Backend Provider: <a href="https://app.bogged.finance" className=" hover:text-yellow-400" target="_blank" rel="noopener noreferrer">Bogged.Finance</a></span>
        <iframe width="100%" height="780px" className="rounded-3xl" src="https://app.bogged.finance/bsc/swap?embed=1&tokenIn=BNB&tokenOut=0xdBEB2e26117A1dd6F5A879139f1252d396ad3c34&theme=dark" frameBorder="0" allowFullScreen></iframe>
      </div>

    </main>
    </div>
  );
}

export default Swap;
