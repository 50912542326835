import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';

const LobbyCreation = ({ isOpen, onClose }) => {
    const { user } = useUser();

    const [lobbyName, setLobbyName] = useState('');
    const [betAmount, setBetAmount] = useState('');
    const [isPrivate, setIsPrivate] = useState(false);
    const [creating, setCreating] = useState(false);
    const [error, setError] = useState(null);
    const [balance, setBalance] = useState(0);

    useEffect(() => {
        if (user && user.email) {
            fetchUserBalance(user.email);
        }
    }, [user]);

    const fetchUserBalance = async (email) => {
        let { data, error } = await supabase
            .from('users')
            .select('balance')
            .eq('email', email)
            .single();

        if (error) {
            console.error('Error fetching user balance:', error);
            setError('Error fetching user balance');
            return;
        }

        setBalance(data?.balance || 0);
    };

    const handleCreateLobby = async () => {
        if (!user || !user.email) {
            setError('You must be logged in to create a lobby.');
            return;
        }
    
        if (betAmount > balance) {
            setError('Insufficient balance to create this lobby.');
            return;
        }
    
        setCreating(true);
        setError(null);
    
        try {
            // Fetch the username using the user's email
            const { data: userData, error: userError } = await supabase
                .from('users')
                .select('username')
                .eq('email', user.email)
                .single();
    
            if (userError || !userData) {
                throw new Error('Unable to fetch user information');
            }
    
            const { error: lobbyError } = await supabase
                .from('lobbies')
                .insert([
                    { 
                        name: lobbyName, 
                        bet_amount: betAmount, 
                        private: isPrivate, 
                        host: userData.username // Use the fetched username
                    }
                ]);
    
            if (lobbyError) {
                throw lobbyError;
            }
    
            // Subtract the bet amount from the user's balance
            await updateBalance(user.email, balance - betAmount);
    
            onClose(); // Close the modal on successful creation
        } catch (error) {
            setError('Error creating lobby: ' + error.message);
        } finally {
            setCreating(false);
        }
    };
    

    const updateBalance = async (email, newBalance) => {
        const { error } = await supabase
            .from('users')
            .update({ balance: newBalance })
            .eq('email', email);

        if (error) {
            console.error('Error updating balance:', error);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center">
            <div className="bg-white p-4 rounded-lg">
                {error && <p className="text-red-500">{error}</p>}

                <div>
                    <label>Lobby Name</label>
                    <input 
                        type="text" 
                        value={lobbyName} 
                        onChange={(e) => setLobbyName(e.target.value)} 
                        className="border-2 border-gray-300 rounded px-4 py-2 w-full"
                    />
                </div>

                <div>
                    <label>Bet Amount</label>
                    <input 
                        type="number" 
                        value={betAmount} 
                        onChange={(e) => setBetAmount(e.target.value)} 
                        className="border-2 border-gray-300 rounded px-4 py-2 w-full"
                    />
                </div>

                <div>
                    <label>
                        <input 
                            type="checkbox" 
                            checked={isPrivate} 
                            onChange={(e) => setIsPrivate(e.target.checked)}
                        />
                        Private Lobby
                    </label>
                </div>

                <div>
                    <button 
                        onClick={handleCreateLobby} 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        disabled={creating}
                    >
                        {creating ? 'Creating...' : 'Create Lobby'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default LobbyCreation;
