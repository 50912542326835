import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import LobbyCreation from '../components/LobbyCreation';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom'; // Import useHistory
import { Link } from'react-router-dom';
import { Helmet } from 'react-helmet';
const Lobbies = () => {
  const [lobbies, setLobbies] = useState([]);
  const [showLobbyCreation, setShowLobbyCreation] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
      fetchLobbies().then(setLobbies);
  }, []);

  const buttonStyle = {
      backgroundImage: `url('/button-bg.png')`,
  };

  const fetchLobbies = async () => {
    const { data: lobbies, error: lobbyError } = await supabase
        .from('lobbies')
        .select('*')
        .order('creation_time', { ascending: false });

    if (lobbyError) {
        console.error('Error fetching lobbies:', lobbyError);
        return [];
    }

    // Fetch host username for each lobby
    for (const lobby of lobbies) {
        if (lobby.host) {
            const hostUsername = await fetchUsername(lobby.host);
            lobby.hostUsername = hostUsername || 'Unknown Host';
        } else {
            lobby.hostUsername = 'Unknown Host'; // Handle case where host is null or not found
        }
    }

    return lobbies;
};

const fetchUsername = async (username) => {
    if (!username) {
        return null; // Return null if username is undefined or null
    }

    const { data, error } = await supabase
        .from('users')
        .select('username')
        .eq('username', username)
        .single();

    if (error) {
        console.error('Error fetching username:', error);
        return null;
    }

    return data?.username;
};

  const toggleLobbyCreationModal = () => {
      setShowLobbyCreation(!showLobbyCreation);
  };

  const handleCancelLobby = async (lobbyId) => {
      // Implement logic to cancel a lobby
      // Ensure that only the creator can cancel the lobby
  };

    const joinLobby = (lobbyId) => {
        // Logic to handle lobby joining
        console.log("Viewing lobby with ID:", lobbyId);
        navigate(`/match/${lobbyId}`); // Navigate to the match page with the lobby ID
      };

  return (
    <div className="Lobbies">
            <Helmet>
      <title>Matchmaking | The Ultimate FIFA Betting Experience</title>
      <meta name="description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:title" content="Matchmaking | The Ultimate FIFA Betting Experience" />
      <meta property="og:description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:image" content="https://cdn.discordapp.com/attachments/798815198668259399/1181781254601982084/Group_58.png?ex=65824e8f&is=656fd98f&hm=7856c32222ec320b59f463cc7958fbd619a193a4dc333bbcde59655a7eb8f96e&" />
      
      {/* Other tags as needed */}
    </Helmet>
        <main className="flex flex-col rounded-3xl min-h-screen bg-[#454054]">
            <div className="p-6 text-white">
                <h1 className="text-4xl font-bold mb-4">Lobbies</h1>
                <p className="text-lg">Join a match and compete against others.</p>
            </div>

            {lobbies.length === 0 ? (
            <div className="text-center text-white p-6">
                <p>There aren't any active lobbies. Create a lobby below to play!</p>
            </div>
        ) : (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-6">
                {lobbies.map((lobby, index) => (
                    <div key={lobby.id} className="bg-[#847492] text-white rounded-3xl p-4 shadow-lg">
                        <div className='mb-2 flex justify-between'>
                        <h2 className="font-bold text-xl">{lobby.name || `Lobby ${index + 1}`}</h2>
                        <p className='text-[#201E26] w-fit font-bold rounded-2xl'>{lobby.status}</p>
                        </div>
                        <p>Bet Amount: {lobby.bet_amount} EAFC</p>
                        <p>@{lobby.hostUsername} vs. {lobby.opponentUsername}!</p>
                        {user && user.username === lobby.host && (
                            <button onClick={() => handleCancelLobby(lobby.id)} className="bg-red-500 text-white rounded-2xl px-4 py-2 mt-2">
                                Cancel Lobby
                            </button>
                        )}
                      <button className='py-2 px-4 mt-4 bg-[#201E26] hover:bg-[#201E2695] duration-100 ease-out text-white font-bold rounded-2xl' onClick={() => joinLobby(lobby.id)}>
                          View
                      </button>
                    </div>
                ))}
            </div>
        )}

            <div className="p-6">
                <button 
                    className="w-full md:w-auto py-2 px-4 rounded-2xl text-white duration-100 ease-out bg-[#201E26] hover:bg-[#201E2695]" 
                    onClick={toggleLobbyCreationModal}
                >
                    Create New Lobby
                </button>
            </div>

            <LobbyCreation 
                isOpen={showLobbyCreation} 
                onClose={toggleLobbyCreationModal}
            />
        </main>
    </div>
);
}

export default Lobbies;
