// LeaveLobbyButton.js
import React from 'react';
import { supabase } from '../supabaseClient';

const LeaveLobbyButton = ({ lobbyId, user, lobbyData, onLeaveSuccess, onLeaveError }) => {
    const handleLeaveLobby = async () => {
        if (!lobbyData || typeof lobbyData.bet_amount === 'undefined') {
            onLeaveError("Lobby data is not available.");
            return;
        }

        try {
            // Fetch the latest user data
            const { data: latestUserData, error: userDataError } = await supabase
                .from('users')
                .select('balance')
                .eq('email', user.email) // or 'username', based on your schema
                .single();

            if (userDataError) throw userDataError;

            // Refund balance logic
            const newBalance = latestUserData.balance + lobbyData.bet_amount;
            const { error: refundError } = await supabase
                .from('users')
                .update({ balance: newBalance })
                .eq('email', user.email); // or 'username'

            if (refundError) throw refundError;

            // Update lobby status
            const { error: lobbyError } = await supabase
                .from('lobbies')
                .update({ opponent: null, status: 'open' })
                .eq('id', lobbyId);

            if (lobbyError) throw lobbyError;

            onLeaveSuccess();
        } catch (error) {
            console.error('Error leaving lobby:', error);
            onLeaveError(error.message);
        }
    };

    return (
        <button className="bg-[#454054] hover:bg-[#45405495] w-[100%] font-bold text-white py-2 px-4 rounded-2xl flex items-center justify-center text-center space-x-2 mb-4" onClick={handleLeaveLobby}>Leave Lobby</button>
    );
};

export default LeaveLobbyButton;
