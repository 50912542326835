// useFetchUserDetails.js
import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const useFetchUserDetails = (username) => {
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!username) return;

        const fetchUserDetails = async () => {
            setLoading(true);
            try {
            const { data, error } = await supabase
                .from('users') // Your table name
                .select('username, email, avatar_url, balance')
                    .eq('username', username)
                    .single();

                
                if (error) throw error;
                setDetails(data);
            } catch (error) {
                console.error('Error fetching user details:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserDetails();
    }, [username]);

    return { details, loading, error };
};

export default useFetchUserDetails;
