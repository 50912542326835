import { Helmet } from 'react-helmet';

import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';
import { Link } from "react-router-dom";
function App() {
  const { user } = useUser();
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
      if (user) {
          fetchUserProfile();
      }
  }, [user]);

  
  const fetchUserProfile = async () => {
      setLoading(true);

      try {
          const userEmail = user.email; // Assuming `user.email` contains the user's email
          const { data, error } = await supabase
              .from('users') // Your table name
              .select('username, email, avatar_url, balance, bsc_address')
              .eq('email', userEmail) // Use email to fetch user data
              .single();
  
          if (error) {
              throw error;
          }
  
          if (data) {
              setProfile(data);
          }
      } catch (error) {
          console.error('Error fetching profile:', error);
      } finally {
          setLoading(false);
      }
  };

  const backgroundImageStyle = (url) => ({
    backgroundImage: `url('${url}')`,
    backgroundPosition: 'center',
  });

  const buttonStyle = {
    backgroundImage: `url('/button-bg.png')`,
  };
  return (
    
    <div className="App">
    <Helmet>
      <title>EAFC24 | The Ultimate FIFA Betting Experience</title>
      <meta name="description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:title" content="EAFC24 | The Ultimate FIFA Betting Experience" />
      <meta property="og:description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:image" content="https://cdn.discordapp.com/attachments/798815198668259399/1181781254601982084/Group_58.png?ex=65824e8f&is=656fd98f&hm=7856c32222ec320b59f463cc7958fbd619a193a4dc333bbcde59655a7eb8f96e&" />
      
      {/* Other tags as needed */}
    </Helmet>
      <main className="flex text-white flex-col rounded-3xl min-h-screen">

      <div className="md:col-span-1">
      <div className="aspect-[4/1] bg-[#454054]  relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={{ backgroundImage: `url('https://cdn.discordapp.com/attachments/798815198668259399/1181781254601982084/Group_58.png?ex=65824e8f&is=656fd98f&hm=7856c32222ec320b59f463cc7958fbd619a193a4dc333bbcde59655a7eb8f96e&')` }}>

        </div>
        </div>

        
        {user ? (
        <div className="grid grid-cols-1 md:grid-cols-3 mt-5 gap-4">
<div className="aspect-[4/3] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={backgroundImageStyle('/soccerball.png')}>
          <Link to="/matchmaking"><button className="w-[90%] absolute inset-x-0 bottom-5 h-[50px] m-auto font-black hover:italic text-2xl flex items-center rounded-3xl justify-center p-6" style={buttonStyle}>
            Find a Match
          </button></Link>
        </div>

        <div className="aspect-[4/3] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={backgroundImageStyle('/chest.png')}>
          <button className="w-[90%] absolute inset-x-0 bottom-5 h-[50px] m-auto font-black hover:scale-2 text-2xl flex items-center rounded-3xl justify-center p-6" style={buttonStyle}>
            Challenges
          </button>
        </div>
        <div className="aspect-[4/3] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={backgroundImageStyle('https://assets-prd.ignimgs.com/2023/04/06/ea-sports-fc-logo-1680798726122.jpg')}>
          <button className="w-[90%] absolute inset-x-0 bottom-5 h-[50px] m-auto font-black hover:scale-2 text-2xl flex items-center rounded-3xl justify-center p-6" style={buttonStyle}>
            Your Stats
          </button>
        </div>
          </div>
        ) : (
          <Link to='/signin'>
          <div className="z-10 min-h-full flex mt-4 py-4 px-4 bg-[#454054] rounded-3xl items-center justify-between 2xl:text-[32px] text-lg font-black lg:flex">
            Sign in to gain access
            <p className="lg:text-lg text-sm font-light opacity-80 text-center lg:text-left">
              👉
            </p>
          </div></Link>
        )}
        


      <div className="z-10 min-h-full mt-4 items-center text-center 2xl:text-left justify-between text-[32px] font-black lg:flex">
        Get Started
        <p className="text-lg lg:text-sm mt-4  mb-2 font-light opacity-80 text-center lg:text-left">
  Join the ultimate EAFC24 betting experience. Compete, dominate, and win!
</p>

      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-5">
        <div className="aspect-[4/3] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={{ backgroundImage: `url('https://www.charlieintel.com/cdn-cgi/image/width=3840,quality=75,format=auto/https://editors.charlieintel.com/wp-content/uploads/2023/07/21/EA-FC-24-best-career-mode-saves.jpeg')` }}>
          <span className="w-[90%] absolute inset-x-0 bottom-5 h-[50px] m-auto font-black select-none text-2xl flex items-center rounded-3xl justify-center p-6" style={{ backgroundImage: `url('/button-bg.png')`, backgroundPosition: 'center' }}>
            Compete
          </span>
          
        </div>
        

        <div className="aspect-[4/3] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={{ backgroundImage: `url('https://assetsio.reedpopcdn.com/EA-Sports-24-FC-header.jpg?width=1200&height=1200&fit=bounds&quality=70&format=jpg&auto=webp')` }}>
          <span className="w-[90%] absolute shadow-lg	hover:shadow-2xl duration-150 ease-out inset-x-0 bottom-5 h-[50px] m-auto font-black text-2xl flex items-center rounded-3xl justify-center p-6" style={{ backgroundImage: `url('/button-bg.png')`, backgroundPosition: 'center' }}>
            Dominate
          </span>
        </div>

        <div className="aspect-[4/3] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={{ backgroundImage: `url('https://cdn.gfinityesports.com/images/ncavvykf/gfinityesports/918d456355c35a46c860e4f934babbfdd8f3748b-1920x1080.jpg?rect=0,36,1920,1008&w=1200&h=630&auto=format')` }}>
          <span className="w-[90%] absolute inset-x-0 bottom-5 h-[50px] m-auto font-black select-none text-2xl flex items-center rounded-3xl justify-center" style={{ backgroundImage: `url('/button-bg.png')`, backgroundPosition: 'center' }}>
            Win
          </span>
        </div>


      </div>
      <div className="grid grid-cols-1 gap-4 mt-5">



        <div className="aspect-[4/1] relative bottom-0 rounded-3xl w-full bg-cover bg-center" style={{ backgroundImage: `url('https://cdn.discordapp.com/attachments/798815198668259399/1182958350581911624/Group_59.png?ex=658696d1&is=657421d1&hm=f0ad5f288c7f83b3be8eac4ee45b75d224d23d027c7c0f2f9a1594b60a496e21&')` }}>

        </div>
        </div>


        {/* Add more sections here with md:col-span-2 for the content underneath */}
    </main>
    </div>
  );
}

export default App;
