import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';
import PlayerInfo from './PlayerInfo';
import ScoreDisplay from './ScoreDisplay';
import ChatBox from './ChatBox';
import WaitingForPlayer from './WaitingForPlayer';
import useFetchUserDetails from './utils/useFetchUserDetails';
import LeaveLobbyButton from './LeaveLobbyButton';
import ResultSubmissionModal from './ResultSubmission';
const MatchPage = () => {
  const { user } = useUser();
  const { lobbyId } = useParams();
  const [lobbyData, setLobbyData] = useState(null);
  const [loggedInUserUsername, setLoggedInUserUsername] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [hostScore, setHostScore] = useState(0);
  const [opponentScore, setOpponentScore] = useState(0);
  const [hostTimeoutsLeft, setHostTimeoutsLeft] = useState(1);
  const [opponentTimeoutsLeft, setOpponentTimeoutsLeft] = useState(1);
  const hostDetails = useFetchUserDetails(lobbyData?.host);
  const opponentDetails = useFetchUserDetails(lobbyData?.opponent);
  const [countdown, setCountdown] = useState(null);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const toggleResultModal = () => {
    setIsResultModalOpen(!isResultModalOpen);
  };

  useEffect(() => {
    if (user) {
      const fetchLoggedInUserProfile = async () => {
        try {
          const { data, error } = await supabase
            .from('users')
            .select('username')
            .eq('email', user.email)
            .single();

          if (error) throw error;
          if (data) setLoggedInUserUsername(data.username);
        } catch (error) {
          console.error('Error fetching logged-in user profile:', error);
        }
      };

      fetchLoggedInUserProfile();
    }
  }, [user]);

  useEffect(() => {
    if (lobbyId) {
      const fetchLobbyData = async () => {
        setLoading(true);
        try {
          const { data, error } = await supabase
            .from('lobbies')
            .select('*')
            .eq('id', lobbyId)
            .single();

          if (error) throw error;
          setLobbyData(data);
        } catch (error) {
          console.error('Error fetching lobby data:', error);
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchLobbyData();
    } else {
      setError('No lobby ID provided');
    }
  }, [lobbyId]);


  useEffect(() => {
    let interval;

    if (!lobbyData || lobbyData.status === 'In Progress') {
      // If there's no lobby data or the game is already in progress, do nothing
      return;
    }
    const startCountdown = () => {
      let seconds = 10;
      setCountdown(seconds);
      interval = setInterval(() => {
        seconds--;
        setCountdown(seconds);
        if (seconds < 0) {
          clearInterval(interval);
          // Update the lobby status to 'In Progress'
          updateLobbyStatusToInProgress();
        }
      }, 1000);
    };

    if (lobbyData.host_ready && lobbyData.opponent_ready) {
      startCountdown();
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [lobbyData]);

  const updateLobbyStatusToInProgress = async () => {
    try {
      const { error } = await supabase
        .from('lobbies')
        .update({ status: 'In Progress' })
        .eq('id', lobbyId);

      if (error) throw error;
      fetchLobbyData(); // Refresh lobby data to reflect the new status
    } catch (error) {
      console.error('Error updating lobby status:', error);
    }
  };

  
  const fetchLobbyData = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('lobbies')
        .select('*')
        .eq('id', lobbyId)
        .single();

      if (error) throw error;
      setLobbyData(data);
    } catch (error) {
      console.error('Error fetching lobby data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleHostReadyClick = async () => {
    try {
      const newReadyStatus = !lobbyData.host_ready;
      const { error } = await supabase
          .from('lobbies')
          .update({ host_ready: newReadyStatus })
          .eq('id', lobbyId);

      if (error) throw error;
      fetchLobbyData();
    } catch (error) {
      console.error('Error updating host ready status:', error);
    }
  };

  const handleOpponentReadyClick = async () => {
    try {
      const newReadyStatus = !lobbyData.opponent_ready;
      const { error } = await supabase
          .from('lobbies')
          .update({ opponent_ready: newReadyStatus })
          .eq('id', lobbyId);

      if (error) throw error;
      fetchLobbyData();
    } catch (error) {
      console.error('Error updating opponent ready status:', error);
    }
  };


  
  const isUserParticipant = loggedInUserUsername && (loggedInUserUsername === lobbyData?.host || loggedInUserUsername === lobbyData?.opponent);
  const isHost = loggedInUserUsername === lobbyData?.host;
  const isUserOpponent = loggedInUserUsername && loggedInUserUsername === lobbyData?.opponent;
  const isInProgress = lobbyData?.status === 'In Progress';

  if (loading) {
    return <div className="text-center p-5">Loading lobby data...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500 p-5">Error: {error}</div>;
  }

  if (!lobbyData) {
    return <div className="text-center text-gray-500 p-5">No lobby data found</div>;
  }

  const { host, opponent, bet_amount: betAmount } = lobbyData;
  const readyField = isHost ? 'host_ready' : 'opponent_ready';
  return (
    <div className="min-h-screen rounded-3xl p-5 flex justify-center">
      <div className="max-w-full w-full rounded-3xl">
        <div className="flex justify-around items-center mb-6">
          <PlayerInfo username={host} avatarUrl={hostDetails?.details?.avatar_url} betAmount={betAmount} />
        <ScoreDisplay 
          hostScore={hostScore} 
          opponentScore={opponentScore} 
          status={lobbyData?.status} // Pass the lobby status here
          hostTimeoutsLeft={hostTimeoutsLeft} 
          opponentTimeoutsLeft={opponentTimeoutsLeft} 
          countdown={countdown >= 0 ? countdown : null}
        />
          {opponent ? (
            <PlayerInfo username={opponent} avatarUrl={opponentDetails?.details?.avatar_url}  betAmount={betAmount} />
          ) : (
            <WaitingForPlayer
              lobbyId={lobbyId}
              user={{ username: loggedInUserUsername }}
              lobbyData={lobbyData}
              onJoinSuccess={() => {
                console.log("Joined lobby successfully!");
                fetchLobbyData();
              }}
              onJoinError={(error) => console.log("Error joining lobby:", error)}
            />
          )}
        </div>
  
        {/* Conditional Rendering Based on Game Progress */}
        {!isInProgress && (
          <>
            {isHost && (
              <button 
                onClick={handleHostReadyClick}
                className={`w-full py-3 text-white font-bold font-lg tracking-tighter rounded-2xl mb-4 ${lobbyData.host_ready ? 'bg-red-500' : 'bg-green-600'}`}
              >
                {lobbyData.host_ready ? 'Cancel' : 'Ready Up!'}
              </button>
            )}
  
            {isUserOpponent && (
              <>
                <button 
                  onClick={handleOpponentReadyClick}
                  className={`w-full py-3 text-white font-bold font-lg tracking-tighter rounded-2xl mb-4 ${lobbyData.opponent_ready ? 'bg-red-500' : 'bg-green-600'}`}
                >
                  {lobbyData.opponent_ready ? 'Cancel' : 'Ready Up!'}
                </button>
                <LeaveLobbyButton
                  lobbyId={lobbyId}
                  user={user}
                  lobbyData={lobbyData}
                  onLeaveSuccess={() => {
                    console.log("Left lobby successfully!");
                    fetchLobbyData();
                  }}
                  onLeaveError={(error) => console.log("Error leaving lobby:", error)}
                />
              </>
            )}
          </>
        )}
  
{/* Finalize Results Button */}
        {isInProgress && (
          <button onClick={toggleResultModal}  className="w-full py-3 text-white font-bold font-lg tracking-tighter rounded-2xl mb-4 bg-blue-500">
            Finalize Results
          </button>
        )}

        {/* Result Submission Modal */}
        {isResultModalOpen && (
          <ResultSubmissionModal
            lobbyId={lobbyId}
            hostUsername={lobbyData?.host}
            opponentUsername={lobbyData?.opponent}
            betAmount={lobbyData?.bet_amount}
            onClose={toggleResultModal}
          />
        )}
  
        <ChatBox />
      </div>
    </div>
  );
  
};

export default MatchPage;
