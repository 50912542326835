import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';

const ProfileEdit = () => {
    const { user } = useUser();
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');
    const [avatarUrl, setAvatarUrl] = useState('');
    useEffect(() => {
        if (user) {
            fetchUserProfile();
        }
    }, [user]);

    const fetchUserProfile = async () => {
        setLoading(true);
        try {
            const { data, error } = await supabase
                .from('users')
                .select('username, avatar_url')
                .eq('email', user.email)
                .single();
    
            if (error) throw error;
    
            if (data) {
                setUsername(data.username);
                setAvatarUrl(data.avatar_url);
                // ... set other profile data if needed
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoading(false);
        }
    };
    
    
    
    

    const handleUpdateProfile = async () => {
        setLoading(true);
        try {
            const updatedData = { username, avatar_url: avatarUrl };
    
            const { error } = await supabase
                .from('users')
                .update(updatedData)
                .eq('email', user.email);
    
            if (error) throw error;
    
            fetchUserProfile();
        } catch (error) {
            console.error('Error updating profile:', error);
        } finally {
            setLoading(false);
        }
    };
    
    
    
    

    const handleSave = () => {
        handleUpdateProfile({
            username: profile.username,
            avatar_url: profile.avatar_url,
            // other fields as necessary
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="container mx-auto p-4">
            <div className="bg-white shadow-md rounded-lg p-6">
                {error && <p className="text-red-500">{error}</p>}
                <div className="mb-4">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        value={profile.username || ''}
                        onChange={(e) => setProfile({ ...profile, username: e.target.value })}
                    />
                </div>
                <div className="mb-4">
                    <label htmlFor="avatarUrl">Avatar URL</label>
                    <input
                        type="text"
                        id="avatarUrl"
                        value={profile.avatar_url || ''}
                        onChange={(e) => setProfile({ ...profile, avatar_url: e.target.value })}
                    />
                </div>
                <button onClick={handleSave} className="bg-blue-500 text-white p-2 rounded">
                    Save Changes
                </button>
            </div>
        </div>
    );
};

export default ProfileEdit;
