import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { Link } from 'react-router-dom'; // Assuming you're using react-router for navigation
import { Helmet } from 'react-helmet';
export default function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage('');
  
    try {
      // Sign up user
      const { user, error: signUpError } = await supabase.auth.signUp({ email, password });
      if (signUpError) throw signUpError;
  
      // Generate a unique username based on the email
      const username = email.split('@')[0]; // Extract the part of the email before the "@" symbol
  
      // Check if a user entry exists in the 'users' table with the same username
      const { data, error: dataError } = await supabase
        .from('users')
        .select('*')
        .eq('username', username);
  
      if (dataError) throw dataError;
  
      // If no user entry exists with the same username, create one
      if (data.length === 0) {
        const { error: insertError } = await supabase
          .from('users')
          .insert([{ email, username, balance: 0 }]);
        
        if (insertError) throw insertError;
      }
  
      // Handle successful sign up
      setMessage('Sign-up successful! Please check your email for the verification link.');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <main className="flex bg-[#454054] flex-col p-5 justify-center items-center rounded-3xl min-h-screen">
    <Helmet>
      <title>Sign Up | The Ultimate FIFA Betting Experience</title>
      <meta name="description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:title" content="Sign Up | The Ultimate FIFA Betting Experience" />
      <meta property="og:description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:image" content="https://cdn.discordapp.com/attachments/798815198668259399/1181781254601982084/Group_58.png?ex=65824e8f&is=656fd98f&hm=7856c32222ec320b59f463cc7958fbd619a193a4dc333bbcde59655a7eb8f96e&" />
      
      {/* Other tags as needed */}
    </Helmet>
   
    <form onSubmit={handleSignUp} className="space-y-4 xl:w-[50%] w-[100%]">
      {/* Error message display */}
      {error && (
        <p className="text-white py-2 px-4 rounded-2xl bg-[rgb(248,49,47)] text-lg font-bold italic">
          {error}
        </p>
      )}
      {/* Email input */}
      <label htmlFor="email" className="block text-lg font-medium text-white tracking-tight">
        E-Mail
      </label>
      <input
        id="email"
        type="email"
        required
        className="w-full py-2 px-4 rounded-2xl mb-5 text-[#201E26] font-medium outline-none"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />

      {/* Password input */}
      <div>
        <label htmlFor="password" className="block text-lg font-medium text-white tracking-tight">
          Password
        </label>
        <input
          id="password"
          type="password"
          required
          className="w-full py-2 px-4 rounded-2xl mt-5 text-[#201E26] font-medium outline-none"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>

      {/* Sign up button */}
      <div>
        <button
          type="submit"
          className="bg-[#201E26] text-white hover:bg-[#201E2695] w-full font-bold mt-8 py-2 px-4 rounded-2xl flex items-center justify-center text-center space-x-2"
          disabled={loading}
        >
          {loading ? 'Signing up...' : 'Sign Up'}
        </button>
      </div>
    </form>
    <div className="block font-medium text-white tracking-tight text-center mt-2">
      <p>
        Already have an account?{' '}
        <Link to="/signin" className="text-blue-300 text-md bg-[#201E26] rounded-2xl py-2 px-4 flex items-center w-fit m-auto mt-2 hover:underline">
          Sign In
        </Link>
      </p>
    </div>
  </main>
  );
}
