import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import { useUser } from '../UserContext';
import { Link } from'react-router-dom';
import FileUpload from '../components/FileUpload';
import UsernameUpdate from '../components/UsernameUpdate';
import { Helmet } from 'react-helmet';

const Profile = () => {
    const { user } = useUser();
    const [profile, setProfile] = useState(null);
    const [history, setHistory] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user) {
            fetchUserProfile();
            fetchUserBetHistory();
        }
    }, [user]);

    const handleLogout = async () => {
        await supabase.auth.signOut();
        // You can also reset the user context or redirect to the home page here
      };    
    const fetchUserProfile = async () => {
        setLoading(true);

        try {
            const userEmail = user.email; // Assuming `user.email` contains the user's email
            const { data, error } = await supabase
                .from('users') // Your table name
                .select('username, email, avatar_url, balance, bsc_address')
                .eq('email', userEmail) // Use email to fetch user data
                .single();
    
            if (error) {
                throw error;
            }
    
            if (data) {
                setProfile(data);
            }
        } catch (error) {
            console.error('Error fetching profile:', error);
        } finally {
            setLoading(false);
        }
    };
    
    const fetchUserBetHistory = async () => {
        try {
            const { data, error } = await supabase
                .from('history')
                .select('*')
                .eq('user_id', user.id);

            if (error) {
                throw error;
            }

            console.log('Fetched user history:', data); // Debug log
            setHistory(data);
        } catch (error) {
            console.error('Error fetching user history:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    // Check if balance is defined
    const formattedBalance = profile?.balance ? `${profile.balance.toFixed(2)} EAFC` : '0.00 EAFC';
    const userAvatar = profile?.avatar_url || "https://cdn.vectorstock.com/i/preview-1x/08/19/gray-photo-placeholder-icon-design-ui-vector-35850819.jpg"; // Replace with dynamic user avatar

    return (
<div className="bg-[#454054]  rounded-3xl min-h-screen">
<Helmet>
      <title>Profile | The Ultimate FIFA Betting Experience</title>
      <meta name="description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:title" content="Profile | The Ultimate FIFA Betting Experience" />
      <meta property="og:description" content="Join the ultimate EAFC24 betting experience. Compete, dominate, and win!" />
      <meta property="og:image" content="https://cdn.discordapp.com/attachments/798815198668259399/1181781254601982084/Group_58.png?ex=65824e8f&is=656fd98f&hm=7856c32222ec320b59f463cc7958fbd619a193a4dc333bbcde59655a7eb8f96e&" />
      
      {/* Other tags as needed */}
    </Helmet>
  <div className="container mx-auto p-5">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {/* Profile Image and Info */}
      <div className="col-span-1 flex flex-col items-center">
        <div className="mb-4 w-full">
          <img
            src={userAvatar}
            alt="Profile"
            className="rounded-3xl w-full h-full aspect-[1/1] object-cover"
          />
          
        </div>
        <div className="mt-4">
          <FileUpload />
        </div>
      </div>
      <div className="col-span-2 mx-auto sm:mx-0">
        <div className="text-5xl font-bold text-white mb-4">
          {profile?.username || user.email.split('@')[0]}
        </div>
        <UsernameUpdate initialUsername={profile?.username || user.email.split('@')[0]} />
        <div className="text-2xl font-bold tracking-tight text-white mt-4 mb-2">Balance: {formattedBalance}</div>
        <div className="text-2xl font-bold tracking-tight text-white mt-4 mb-2">Current Level: N/A</div>
        <div className="text-2xl font-bold tracking-tight text-white mt-4 mb-2">Next Level @ N/A XP</div>

      </div>
    </div>
    {/* Additional Sections */}
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-6">
      {/* Left Section */}
      <div className="col-span-1">
        <div className="bg-[#201E26] rounded-2xl p-4">
          <h2 className="text-xl font-medium  tracking-tight text-white mb-2">User Info</h2>
          <p className="text-white text-lg font-medium tracking-tight ">Username: {profile.username || 'Not set'}</p>
          <p className="text-white text-lg font-medium tracking-tight ">Email: {profile.email || 'Not set'}</p>
          <p className="text-white text-lg font-medium tracking-tight ">Wallet Address: {profile?.bsc_address || 'Not set'}</p>
          <p className="text-white text-lg font-medium tracking-tight ">Main Platform: {profile?.platform || 'Not set'}</p>

          {/* Add more user info fields as needed */}
        </div>
      </div>
      {/* Middle Section */}
      <div className="col-span-1">
        <div className="bg-[#201E26] min-h-full rounded-2xl p-4">
          <h2 className="text-xl font-medium text-white mb-2">Activity</h2>
          <p className="text-white text-sm  font-medium tracking-tight ">Coming Soon</p>

          {/* Add user activity, such as recent actions or posts */}
        </div>
      </div>
      {/* Right Section */}
      <div className="col-span-1">
        <div className="bg-[#201E26] min-h-full rounded-2xl p-4">
          <h2 className="text-xl font-medium text-white mb-2">Settings</h2>
          <p className="text-white text-sm font-medium tracking-tight ">Coming Soon</p>

          {/* Add user settings and preferences */}
        </div>
      </div>
    </div>
    {/* Log Out Button */}
    <div className="mt-6">
      <button
        onClick={handleLogout}
        className="py-2 px-4 bg-[#201E26] duration-100 ease-out hover:bg-red-400 text-white font-bold rounded-2xl"

      >
        Log Out
      </button>
    </div>
  </div>
</div>






    );
};

export default Profile;
